import React, { useEffect } from "react";

import Particles from "react-particles-js";
import particlesConfig from "../../config/particlesConfig.js";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <section
      class="d-flex align-items-center min-vh-100"
      style={{
        backgroundColor: "#000",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Particles
        style={{
          position: "absolute",
          overflow: "hidden",
          top: 0,
          width: "100%",
          height: "100%",
        }}
        params={particlesConfig}
      />

      <div class="container pt-4 pb-5 py-lg-5" style={{ zIndex: 999 }}>
        <div class="row align-items-center">
          <div className="col-lg-8 my-5">
            <h1 className="text-light">Smart Travel 2021</h1>
            <p className="text-light">
              Setur tarafından turizm sektörünün günümüz teknolojisi ile
              güçlendirilmesi vizyonuyla, Divan ve Otokoç kuruluşlarının
              desteğiyle düzenlenen Hackathon için geri sayım başladı! Turizmde
              Dijitalleşmeye yönelik bu yarışmada yenilikçi fikirlerini
              yazılımla birleştir farkını göster.
            </p>
            <p className="text-light">
              11-19 Aralık 2021 tarihleri arasında düzenlenecek olan Smart
              Travel 2021 için birlikte geliştirmeye hazır mısınız? <br />
              <span className="font-weight-bold">
                Son Başvuru Tarihi 7 Aralık 2021
              </span>
            </p>

            <Link
              to="/kaydol"
              style={{ textDecoration: "none" }}
              class="btn btn-light mt-4 disabled"
            >
              Başvur
            </Link>
            <p className="text-light mt-3">
              Smart Travel 2021 başvuruları sona ermiştir.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
