import React from "react";

export default function Footer() {
  return (
    <div className="w-100 py-md-5" style={{ backgroundColor: "#000" }}>
      <div className="container">
        <div className="row text-center justify-content-center">
          {/*  <div className="col-xs-12 my-5">
            <button
              type="button"
              class="btn btn-outline-secondary footer-apply-button"
            >
              Kayıt Ol
            </button>
          </div> */}

          <ul class="nav justify-content-center my-5">
            <li class="nav-item">
              <a class="nav-link" href="https://www.linkedin.com/company/setur">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.facebook.com/setur">
                <i class="fab fa-facebook"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.instagram.com/setur/">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
          <div className="row justify-content-between d-none d-sm-flex">
            <div className="col text-start p-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo/koc.webp"}
                width="170"
                className="img img-fluid"
              />
            </div>

            <div className="col text-sm-end p-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo/setur-w.webp"}
                width="160"
                className="img img-fluid"
              />
            </div>
          </div>

          <div className="row justify-content-center d-sm-none">
            <div className="col-6 text-center p-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo/koc.webp"}
                width="100"
                className="img img-fluid"
              />
            </div>

            <div className="col-6 text-center p-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo/setur-w.webp"}
                width="100"
                className="img img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
