import React from "react";

export default function Lecturer() {
  return (
    <div className="container py-md-5" id="egitmenler">
      <div className="row">
        <div className="col-md-4 col-lg-3 col-xs-12 mt-4">
          <h2 className="text-center d-sm-none">Jüriler</h2>
          <div class="d-none d-sm-inline">
            <div class="card-body p-0">
              <h2>Eğitmenler</h2>
              <br />
              <br />
              <i class="fas fa-long-arrow-alt-right right-icon"></i>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 p-0">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/egitmen/sinan-kalkan.webp"
                }
                alt=""
              />
              <div className="my-3">
                <div className="my-3">
                  <a
                    href="https://www.linkedin.com/in/sinan-kalkan-9910195/?originalSubdomain=tr/"
                    target="_blank"
                    class="card-link"
                  >
                    Doç Dr. Sinan Kalkan <i class="fab fa-linkedin"></i>
                  </a>
                  <p className="title-juri">
                    Orta Doğu Teknik Üniversitesi, Bilgisayar Mühendisliği
                    Bölümü
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 ">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/egitmen/gizem-deniz.webp"
                }
                alt=""
              />
              <div className="my-3">
                <a
                  href="https://www.linkedin.com/in/gizemdeniz/"
                  target="_blank"
                  class="card-link"
                >
                  Gizem Deniz KARAKAYA <i class="fab fa-linkedin"></i>
                </a>
                <p className="title-juri">
                  KWORKS Hızlandırma Programları Uzman Yardımcısı
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
