import React from "react";

export default function Prizes() {
  return (
    <div className="container py-md-5" id="oduller">
      <div className="row text-center">
        <div className="col-xs-12 mb-5 pt-3">
          <h2>Ödüller</h2>
        </div>
        <div className="col-lg-4 d-sm-none">
          <div
            className="card"
            style={{ backgroundColor: "rgb(248, 248, 248)" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/prizes/gold.webp"}
              width="128"
              className="img img-fluid mx-auto"
            />
            <div class="card-body">
              <p className="mt-3 prize-text">12.000₺</p>
              <p>
                Her ekip üyesine Otokoç'tan <br />1 günlük karavan kiralama
              </p>
              <p>
                Her ekip üyesine Pizza Pazzi’de <br />2 kişilik akşam yemeği
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="card"
            style={{ backgroundColor: "rgb(248, 248, 248)", height: "100%" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/prizes/silver.webp"}
              width="112"
              className="img img-fluid pt-3 mx-auto"
            />
            <div class="card-body">
              <p className="mt-3 prize-text">9.000₺</p>
              <p>
                Her ekip üyesine Otokoç'ta <br />
                staj imkanı
              </p>
              <p>Her ekip üyesine Beyoğlu Brasserie’de 2 kişilik öğle yemeği</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 d-none d-sm-inline">
          <div
            className="card"
            style={{
              backgroundColor: "rgb(248, 248, 248)",
              height: "100%",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/prizes/gold.webp"}
              width="128"
              className="img img-fluid mx-auto"
            />
            <div class="card-body">
              <p className="mt-3 prize-text">12.000₺</p>
              <p>
                Her ekip üyesine Otokoç'tan <br />1 günlük karavan kiralama
              </p>
              <p>Her ekip üyesine Pizza Pazzi’de 2 kişilik akşam yemeği</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="card"
            style={{ backgroundColor: "rgb(248, 248, 248)", height: "100%" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/prizes/bronze.webp"}
              width="112"
              className="img img-fluid pt-3 mx-auto"
            />
            <div class="card-body">
              <p className="mt-3 prize-text">6.000₺</p>
              <p>
                Her ekip üyesine Zipcar'dan <br />5 saat araç kiralama
              </p>
              <p>Her ekip üyesine 200₺ divanpastaneleri.com.tr hediye çeki</p>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div
            className="card py-5"
            style={{ backgroundColor: "rgb(248, 248, 248)" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/prizes/special.webp"}
              width="80"
              className="img img-fluid mx-auto"
            />
            <div class="card-body">
              <h4 className="mt-3">Nakit Ödüllere Ek Olarak</h4>
              <p className="text-center">
                Bir ekibe Koç Üniversitesi Girişimcilik Merkezi Kworks Ön
                Kuluçka Programına katılım hakkı
              </p>
              <p className="text-center">
                İlk üçe giren takımlar, Microsoft tarafından sunulan Azure
                platformdan bir eğitim almaya hak kazanır. <br />
                Eğitimler: Azure Training Reward for IT Pro Package, Azure
                Training Reward for Developers Package, Azure Training Reward
                for Data & Ai Engineering
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
