import React from "react";
import { Link } from "react-router-dom";

export default function Topics() {
  return (
    <div className="container py-md-5">
      <div className="row">
        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <h2 className="text-center d-sm-none">Hackathon Konuları</h2>
          <div class="d-none d-sm-inline">
            <div class="card-body p-0">
              <h2>
                Hack-
                <br />
                athon <br />
                Konuları
              </h2>
              <br />
              <i class="fas fa-long-arrow-alt-right right-icon"></i>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card topic-card">
            <div class="card-body text-center py-4">
              {/* <p className="card-icon"></p> */}
              <h5 class="card-title topic-title">
                Etkileşimli Turist Haritası
              </h5>
              <p class="card-text topic-description">
                Dijital Etkileşimli (Responsive) turist haritası ve şehir
                rehberi, Kalabalık Kontrolü ve Akıllı Turizm Destinasyonu
                özellikleri ile donatılmış bir mobil uygulama.
              </p>

              <p>Mentor</p>
              <a
                href="https://www.linkedin.com/in/dilara-albayrak-45b284193/"
                style={{ textDecoration: "none" }}
                class="card-link"
                target="_blank"
              >
                Dilara Albayrak <i class="fab fa-linkedin"></i>
              </a>
              <p className="title">
                Setur, Ar-Ge Uzmanı <br />
                <span class="card-text">dilara.albayrak@setur.com.tr</span>
              </p>

              <a
                href="https://www.linkedin.com/in/dilara-albayrak-45b284193/"
                style={{ textDecoration: "none" }}
                class="card-link"
                target="_blank"
              >
                Yiğit Sevim <i class="fab fa-linkedin"></i>
              </a>
              <p className="title pb-0 mb-0">
                Setur, Ar-Ge Uzmanı <br />
                <span class="card-text">yigit.sevim@setur.com.tr</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card topic-card">
            <div class="card-body text-center py-4">
              <h5 class="card-title topic-title">
                Akıllı Otel Fiyat Karşılaştırma
              </h5>
              <p class="card-text topic-description">
                Otel fiyatlarını ve doluluk durumlarını tüm web siteleri ve
                acentelerden karşılaştıran bir servis.
              </p>
              <p>Mentor</p>
              <a
                href="https://www.linkedin.com/in/mustafa-celen-40b8659b/"
                style={{ textDecoration: "none" }}
                class="card-link"
              >
                Mustafa Çelen <i class="fab fa-linkedin"></i>
              </a>
              <p className="title">
                Setur, Ar-Ge Uzmanı <br />
                <span class="card-text">mustafa.celen@setur.com.tr</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card topic-card">
            <div class="card-body text-center py-4">
              <h5 class="card-title topic-title">
                Görüntü İşleme ile Duygu Analizi
              </h5>
              <p class="card-text topic-description">
                Görüntü işleme teknolojileri kullanılarak müşteri duygu analiz
                raporu oluşturulması.
              </p>
              <p>Mentor</p>
              <a
                href="https://www.linkedin.com/in/melisa-ankut-49003217b/"
                style={{ textDecoration: "none" }}
                class="card-link"
              >
                Melisa Ankut <i class="fab fa-linkedin"></i>
              </a>
              <p className="title">
                Setur, Ar-Ge Uzmanı <br />
                <span class="card-text">melisa.ankut@setur.com.tr</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card topic-card">
            <div class="card-body text-center py-4">
              <h5 class="card-title topic-title">
                Davranış Modeli ve Segmentasyon
              </h5>
              <p class="card-text topic-description">
                Müşteri satış verisinden ve diğer kaynaklardan yararlanarak
                kullanıcı davranış modelleme ve çıkarım yapma.
              </p>
              <p>Mentor</p>
              <a
                href="https://www.linkedin.com/in/mert-akyol-14b441104/"
                style={{ textDecoration: "none" }}
                class="card-link"
              >
                Mert Akyol <i class="fab fa-linkedin"></i>
              </a>
              <p className="title">
                Setur, Ar-Ge Uzmanı <br />
                <span class="card-text">mert.akyol@setur.com.tr</span>
              </p>
              <a
                href="https://www.linkedin.com/in/caner-sezgin-978485198/"
                style={{ textDecoration: "none" }}
                class="card-link"
              >
                Caner Sezgin <i class="fab fa-linkedin"></i>
              </a>
              <p className="title pb-0 mb-0">
                Setur, Ar-Ge Uzmanı <br />
                <span class="card-text">caner.sezgin@setur.com.tr</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card topic-card">
            <div class="card-body text-center py-4">
              <h5 class="card-title topic-title">Akıllı Karavan Kiralama</h5>
              <p class="card-text topic-description">
                Karavan turizmi için akıllı veya yeni teknolojiler kullanarak
                dijital platform oluşturulması; karavan kiralama, kamp
                rezervasyonu gibi araçları olan web sitesi tasarlanması.
              </p>
              <p>Mentor</p>
              <a
                href="https://www.linkedin.com/in/burcu-acahan/"
                style={{ textDecoration: "none" }}
                class="card-link"
              >
                Burcu Acahan <i class="fab fa-linkedin"></i>
              </a>
              <p className="title">
                Avis, Karavan Satış Müdürü <br />
                <span class="card-text">
                  burcu.acahan@otokocotomotiv.com.tr
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card topic-card">
            <div class="card-body text-center py-4">
              <h5 class="card-title topic-title">
                Otokoç Makine Öğrenmesi Meydan Okuma
              </h5>
              <p class="card-text topic-description">
                Otokoç'un sağlayacağı satış veriler üzerinden makine öğrenmesi
                uygulaması.
              </p>
              <p>Mentor</p>
              <a
                href="https://www.linkedin.com/in/burcu-acahan/"
                style={{ textDecoration: "none" }}
                class="card-link"
              >
                Betül Toklu <i class="fab fa-linkedin"></i>
              </a>
              <p className="title">
                Otokoç, Ürün Yönetim Yöneticisi <br />
                <span class="card-text">betul.toklu@otokocotomotiv.com.tr</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card topic-card">
            <div class="card-body text-center py-4">
              <h5 class="card-title topic-title">
                Divan Makine Öğrenmesi Meydan Okuma
              </h5>
              <p class="card-text topic-description">
                Divan Pastaneleri'nin sağlayacağı veriler ile ürün, tedarik ve
                stok tahminlemeleri için makine öğrenmesi uygulaması
              </p>
              <p>Mentor</p>
              <a
                href="https://www.linkedin.com/in/elifsahintas/"
                style={{ textDecoration: "none" }}
                class="card-link"
              >
                Elif Şahintaş <i class="fab fa-linkedin"></i>
              </a>
              <p className="title">
                Divan, Bilgi Teknolojileri Müdürü <br />
                <span class="card-text">elif.sahintas@divan.com.tr</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
