import React from "react";
import { Link } from "react-router-dom";

export default function FAQ() {
  return (
    <div className="container py-md-5" id="sss">
      <div className="row">
        <div className="col-xs-12 mb-5 pt-3 text-center">
          <h2>S.S.S.</h2>
        </div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Smart Travel Hackathon nedir?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Smart Travel Hackathon, fark yaratan seyahat fikirleri, akıllı
                turizm uygulamaları, makine öğrenmesi, derin öğrenme, veri
                madenciliği, doğal dil işleme gibi yapay zekâ uygulamalarını
                geliştirmek amaçlı bir yarışmadır.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Hackathon online mı olacak?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Oturumlar online gerçekleştirilecek proje çalışmalarını gruplar
                uzaktan yürütecektir.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Yarışmada hangi veriler kullanılabilir?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Setur.com.tr, Tripadvisor.com, Hotels.com gibi turizm
                sitelerinden elde edebileceğiniz veriler ile ihtiyaç duyduğunuz
                başka veri setlerini (hava durumu, yolcu istatistikleri, döviz
                kuru vb.) kullanabilirsiniz.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Hangi programlama dilleri ve platformlar kullanılarak geliştirme
                yapılabilir?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                İstenilen dil ve geliştirme platformunu kullanabilirsiniz.
                Geliştirilen projeler yarışma sonunda belirtilen ortama
                yüklenecektir.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                Hackathon öncesinde projemin yazılımını geliştirebilir miyim?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Hayır, yazılım geliştirme yalnızca hackathon zamanında
                yapılabilir. Geçmişte yaptığınız veya farklı bir etkinlikte
                sunduğunuz projeler diskalifiye edilir.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                Hackathon'a katılmak ücretli mi?
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Hayır, katılımcılardan herhangi bir ücret talep edilmeyecektir.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                Hackathon'a nasıl kaydolabilirim?
              </button>
            </h2>
            <div
              id="flush-collapseSeven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSeven"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Link
                  to="/kaydol"
                  style={{ textDecoration: "none" }}
                  class="link"
                >
                  Kayıt formunu
                </Link>{" "}
                7 Aralık'a kadar eksiksiz doldurmanız yeterlidir. Başvurusu
                alınan takımlara onay e-maili gönderilecektir.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEight"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                Hackathon'a Setur, Divan Otokoç, Microsoft çalışanları ve
                stajyerleri katılabilir mi?
              </button>
            </h2>
            <div
              id="flush-collapseEight"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingEight"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Hayır. Setur, Divan Otokoç, Microsoft çalışanları ve stajyerleri
                katılamayacaktır.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingNine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNine"
                aria-expanded="false"
                aria-controls="flush-collapseNine"
              >
                Projem ve projemin fikri mülkiyeti kime ait olacaktır?
              </button>
            </h2>
            <div
              id="flush-collapseNine"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingNine"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Link
                  to="/katilim-sozlesmesi"
                  style={{ textDecoration: "none" }}
                  class="link"
                  target="_blank"
                >
                  Başvuru şartları
                </Link>
                nda yer almaktadır.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTen"
                aria-expanded="false"
                aria-controls="flush-collapseTen"
              >
                Takımlar kaç kişiden oluşabilir?
              </button>
            </h2>
            <div
              id="flush-collapseTen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTen"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Takımlar 3 veya 4 kişiden oluşabilir.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEl">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEl"
                aria-expanded="false"
                aria-controls="flush-collapseEl"
              >
                Değerlendirme kriterleri nelerdir?
              </button>
            </h2>
            <div
              id="flush-collapseEl"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingEl"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Setur Travel Smart Tech’in seçkin jürisi hackathon sonunda
                çalışmaları titizlikle değerlendirecektir.
                <ul>
                  <li>
                    Proje Neden Değerli? Ne kadar yenilikçi? Hayata
                    geçirildiğinde nasıl bir etkisi olacak?
                  </li>
                  <li>MVP / Yazılım %50</li>
                  <li>Analiz ne düzeyde</li>
                  <li>Tasarım ne düzeyde</li>
                  <li>Veri mühendisliği</li>
                  <li>Geliştirme başarı kriterleri</li>
                  <li>Sunum ve Demo %25 </li>
                  <li>
                    Sunumun zamanında teslim edildi mi? Sunum yetkinliği ne
                    düzeyde?
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTw">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTw"
                aria-expanded="false"
                aria-controls="flush-collapseTw"
              >
                Hackathon'a kimler katılabilir?
              </button>
            </h2>
            <div
              id="flush-collapseTw"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTw"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Teknoloji ve inovasyona ilgi duyan, yazılım geliştiren, lisans
                ve yüksek lisans öğrencileri katılabilir.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
