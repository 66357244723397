import React from "react";

export default function Cal() {
  return (
    <div style={{ backgroundColor: "#f8f8f8" }}>
      <span className="d-sm-none">
        <div className="container-fluid py-md-5 mt-5" id="takvim">
          <div className="row justify-content-center">
            <div className="col-xs-12 mb-5 pt-3 text-center">
              <h2>Takvim</h2>
            </div>
            <div className="col-md-10 col-xs-12 desktop p-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/cal-b.webp"}
                width="100%"
                className="img img-fluid"
              />
            </div>
          </div>
        </div>
      </span>
      <span className="d-none d-sm-block">
        <div className="container py-md-5 mt-5" id="takvim">
          <div className="row p-0 m-5 pb-5 justify-content-center">
            <div className="col-xs-12 mb-5 pt-3 text-center">
              <h2>Takvim</h2>
            </div>
            <div className="col-md-10 col-xs-12 desktop p-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/cal-b.webp"}
                width="100%"
                className="img img-fluid"
              />
            </div>
          </div>
        </div>
      </span>
    </div>
  );
}
