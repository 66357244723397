import React from "react";

export default function SubFooter() {
  return (
    <div className="sub-footer-black-bg">
      <div className="container">
        <div className="row">
          <p className="text-end text-white footer-xathon-text d-none d-sm-block">
            Introduced by
            <a href="http://xathon.co" target="_blank">
              <img
                className="img img-fluid footer-xathon-logo"
                src={process.env.PUBLIC_URL + "/assets/logo/xathon-w.webp"}
              />
            </a>
          </p>

          <p className="text-center text-white footer-xathon-text d-sm-none">
            Introduced by
            <a href="http://xathon.co" target="_blank">
              <img
                className="img img-fluid footer-xathon-logo"
                src={process.env.PUBLIC_URL + "/assets/logo/xathon-w.webp"}
              />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
