import React from "react";

export default function Privacy() {
  return (
    <div className="container">
      <div className="row">
        <object
          data={process.env.PUBLIC_URL + "assets/aydinlatma-metni.pdf"}
          type="application/pdf"
          className="min-vh-100 col-12"
        ></object>
      </div>
    </div>
  );
}
