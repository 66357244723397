import React from "react";
import Cal from "./Cal";
import FAQ from "./FAQ";
import Juri from "./Juri";
import Lecturer from "./Lecturer";
import Mentor from "./Mentor";
import Partners from "./Partners";
import Prizes from "./Prizes";
import Footer from "./shared/Footer";
import Header from "./shared/Header";
import Navigation from "./shared/Navigation";
import SubFooter from "./shared/SubFooter";
import Sponsor from "./Sponsor";
import Topics from "./Topics";

export default function Landing() {
  return (
    <div>
      <Navigation />
      <Header />
      <Partners />
      <Sponsor />
      <Topics />
      {/* <Mentor /> */}
      <Prizes />
      <Cal />
      <Juri />
      <Lecturer />
      <FAQ />
      <Footer />
      <SubFooter />
    </div>
  );
}
