import React from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <nav
      class="navbar navbar-expand-sm w-100 mt-md-3"
      style={{ position: "absolute", zIndex: 999 }}
    >
      <div class="container">
        <a class="navbar-brand">
          <img
            src={process.env.PUBLIC_URL + "/assets/logo/logo-w.png"}
            alt=""
            width="200"
            height="30"
          />
        </a>
        {/*  <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button> */}
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="me-auto"></ul>
          <ul class="navbar-nav me-1">
            <span className="d-none d-lg-flex">
              <li class="nav-item">
                <a class="nav-link" href="#partnerler">
                  Partnerler
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#sponsor">
                  Sponsorlar
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#oduller">
                  Ödüller
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#takvim">
                  Takvim
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#juriler">
                  Jüriler
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#egitmenler">
                  Eğitmenler
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#sss">
                  S.S.S
                </a>
              </li>
            </span>
            <li class="nav-item ms-4">
              <Link
                to="/kaydol"
                style={{ textDecoration: "none" }}
                class="btn btn-light disabled"
              >
                Başvur
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
