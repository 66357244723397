import React from "react";

export default function Juri() {
  return (
    <div className="container py-md-5 mt-5" id="juriler">
      <div className="row mt-5">
        <div className="col-12 my-5">
          <h2 className="text-center">Jüriler</h2>
        </div>
        {/* <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <h2 className="text-center d-sm-none">Jüriler</h2>
          <div class="d-none d-sm-inline">
            <div class="card-body p-0">
              <h2>Jüriler</h2>
              <br />
              <br />
              <i class="fas fa-long-arrow-alt-right right-icon"></i>
            </div>
          </div> 
        </div>*/}

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 p-0">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/jury/alphan-kimyonk.webp"
                }
                alt=""
              />
              <div className="my-3">
                <div className="my-3">
                  <a
                    href="https://www.linkedin.com/in/alphan-kimyonok-b2355618/"
                    target="_blank"
                    class="card-link"
                  >
                    Alphan Kimyonok <i class="fab fa-linkedin"></i>
                  </a>
                  <p className="title-juri">
                    Setur, Bilgi Teknolojileri ve Ar-Ge Direktörü
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 p-0">
              <img
                className="img img-fluid"
                src={process.env.PUBLIC_URL + "/assets/img/jury/elif-acar.webp"}
                alt=""
              />
              <div className="my-3">
                <div className="my-3">
                  <a
                    href="https://www.linkedin.com/in/elifacarozguner/"
                    target="_blank"
                    class="card-link"
                  >
                    Elif Acar Özgüner <i class="fab fa-linkedin"></i>
                  </a>
                  <p className="title-juri">
                    Microsoft, Genel Müdür Yardımcısı
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 p-0">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL + "/assets/img/jury/meltem-yondem.webp"
                }
                alt=""
              />
              <div className="my-3">
                <div className="my-3">
                  <a
                    href="https://www.linkedin.com/in/meltem-turhan-yöndem-347a0329/"
                    target="_blank"
                    class="card-link"
                  >
                    Meltem Turhan <i class="fab fa-linkedin"></i>
                  </a>
                  <p className="title-juri">
                    Kartezyen Danışmanlık, Kurucu Ortak
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/jury/mahmut-ozdemir.webp"
                }
                alt=""
              />
              <div className="my-3">
                <a
                  href="https://www.linkedin.com/in/mnozdemir/"
                  target="_blank"
                  class="card-link"
                >
                  Mahmut N. Özdemir <i class="fab fa-linkedin"></i>
                </a>
                <p className="title-juri">KWORKS, Direktör</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 ">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/jury/onur-tolga-sehitoglu.webp"
                }
                alt=""
              />
              <div className="my-3">
                <a
                  href="https://www.linkedin.com/in/onur-sehitoglu-a7516a5/"
                  target="_blank"
                  class="card-link"
                >
                  Onur Tolga Şehitoğlu <i class="fab fa-linkedin"></i>
                </a>
                <p className="title-juri">
                  Orta Doğu Teknik Üniversitesi, Bilgisayar Mühendisliği Bölümü
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 ">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/jury/dogan-necip-mersin.webp"
                }
                alt=""
              />
              <div className="my-3">
                <a
                  href="https://www.linkedin.com/in/doganmersin/"
                  target="_blank"
                  class="card-link"
                >
                  Doğan Necip Mersin <i class="fab fa-linkedin"></i>
                </a>
                <p className="title-juri">
                  Divan, Bilgi Teknolojileri & Dijital Dönüşüm Direktörü
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-xs-12 mt-4">
          <div class="card p-0">
            <div class="card-body text-center p-0 ">
              <img
                className="img img-fluid"
                src={
                  process.env.PUBLIC_URL + "/assets/img/jury/erdal-kemikli.webp"
                }
                alt=""
              />
              <div className="my-3">
                <a
                  href="https://www.linkedin.com/in/erdalkemikli/"
                  target="_blank"
                  class="card-link"
                >
                  Erdal Kemikli <i class="fab fa-linkedin"></i>
                </a>
                <p className="title-juri">
                  Otokoç Otomotiv, Teknoloji ve Strateji Direktörü
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
