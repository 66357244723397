import React from "react";

export default function Sponsor() {
  return (
    <div className="container py-md-5" id="sponsor">
      <div className="row justify-content-around">
        <div className="col-12 my-5">
          <h2 className="text-center">Sponsorlar</h2>
        </div>

        <div className="col text-center">
          <img
            src={process.env.PUBLIC_URL + "/assets/logo/kworks.webp"}
            width="200"
            className="img img-fluid"
          />
        </div>

        <div className="col text-center">
          <img
            src={process.env.PUBLIC_URL + "/assets/logo/microsoft.webp"}
            width="200"
            className="img img-fluid"
          />
        </div>
      </div>
    </div>
  );
}
