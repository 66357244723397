import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./components/Landing";
import Apply from "./components/Apply";
import Privacy from "./components/Privacy";
import Policy from "./components/Policy";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          {/* <Route exact path="/kaydol">
            <Apply />
          </Route> */}
          <Route exact path="/aydinlatma-metni">
            <Privacy />
          </Route>
          <Route exact path="/katilim-sozlesmesi">
            <Policy />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
